import _axios, { post, get, _delete, requestUrl } from '@/lin/plugin/axios'

class CsMng {
  //获取时间
  async getDateList(data) {
    return await post(`/adminChain/store/time-slot/dateList`, data)
  }

  //获取时段
  async getTimeSlotList(data) {
    return await post(`/adminChain/store/time-slot/list`, data)
  }
  //添加特殊日期
  async addSpecialDate(data) {
    return await post(`/adminChain/store/time-slot/special-date/add`, data)
  }
  //删除特殊日期
  async deleteSpecialDate(data) {
    return await post(`/adminChain/store/time-slot/deleteSpecialDate`, data)
  }

  //删除时段
  async deleteTimeSlot(data) {
    return await post(`/adminChain/store/time-slot/delete`, data)
  }
  //添加时段
  async addTimeSlot(data) {
    return await post(`/adminChain/store/time-slot/add`, data)
  }
  //复制时段
  async copyTimeSlot(data) {
    return await post(`/adminChain/store/time-slot/copy`, data)
  }

  //包厢
  //获取包厢类型
  async getBoxTypeList(data) {
    return await get(`/adminChain/store/getStoreRoomTypeList`, data)
  }
  //添加包厢类型
  async addBoxType(data) {
    return await post(`/adminChain/store/addRoomType`, data)
  }
  //删除包厢类型
  async deleteBoxType(data) {
    return await post(`/adminChain/store/deleteRoomType`, data)
  }
  //修改包厢类型
  async updateBoxType(data) {
    return await post(`/adminChain/store/updateRoomType`, data)
  }
  //总部门店类型
  async getStoreTypeList() {
    return await get(`/adminChain/store/getParentRoomTypeList`)
  }

  //获取包厢列表
  async getBoxList(data) {
    return await get(`/adminChain/store/getStoreRoomList`, data)
  }

  //添加包厢
  async addBox(data) {
    return await post(`/adminChain/store/addStoreRoom`, data)
  }

  //修改包厢
  async updateBox(data) {
    return await post(`/adminChain/store/updateStoreRoom`, data)
  }

  //删除包厢
  async deleteBox(data) {
    return await post(`/adminChain/store/deleteStoreRoom`, data)
  }

  //门店方案列表
  async getStoreSchemeList(data) {
    return await post(`/adminChain/store/price-scheme/list`, data)
  }

  //保存方案
  async saveStoreScheme(data) {
    return await post(`/adminChain/store/price-scheme/save`, data)
  }

  // 套餐搜索
  async getComboList(data) {
    return await requestUrl({
      url: '/adminChain/storeProduct/combo/list',
      method: 'post',
      data: data,
    })
  }
  // 上下架
  async ShelvesChange(data) {
    return await requestUrl({
      url: '/adminChain/storeProduct/combo/shelves-change',
      method: 'post',
      data: data,
    })
  }
  // 查询门店套餐
  async getComboPage(data) {
    return await requestUrl({
      url: '/adminChain/storeProduct/combo/page',
      method: 'post',
      data: data,
    })
  }
  // 查询预定设置
  async getPresetSet(data) {
    return await requestUrl({
      url: '/adminChain/mini-reserve/find-reserve',
      method: 'post',
      data: data,
    })
  }
  // 更新预定设置
  async updatePresetSet(data) {
    return await requestUrl({
      url: '/adminChain/mini-reserve/update-reserve',
      method: 'post',
      data: data,
    })
  }
}
export default new CsMng()
